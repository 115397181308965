import React from 'react';
import { useDataGrid, DataGrid, GridColumns, List } from '@pankod/refine-mui';

import { IOrders } from 'interfaces';
import { useNavigate } from 'react-router-dom';

export const OrdersList: React.FC = () => {
  const navigate = useNavigate();
  // get base path from router
  const basePath = window.location.pathname;
  const { dataGridProps } = useDataGrid<IOrders>({
    initialSorter: [{ field: 'createdAt', order: 'desc' }],
  });

  const columns = React.useMemo<GridColumns<IOrders>>(
    () => [
      {
        field: 'content',
        headerName: 'Nom',
        flex: 0.4,
        renderCell: (params) => `${params.value.customerName}`,
      },
      {
        field: 'createdAt',
        headerName: 'Data achat',
        flex: 0.3,
        renderCell: (params) =>
          `${new Date(params.value).toLocaleDateString('fr-FR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })}`,
      },
      {
        field: 'total',
        headerName: 'Montant',
        flex: 0.2,
        // add € symbol
        renderCell: (params) => `${params.value} €`,
      },
      // {
      //   field: 'used',
      //   headerName: 'Utilisée',
      //   flex: 0.5,
      //   // if true display "Oui" else "Non"
      //   renderCell: (params) => (params.value ? 'Oui' : 'Non'),
      // },
    ],
    []
  );

  return (
    <List
      wrapperProps={{
        sx: {
          marginTop: '3rem',
        },
      }}
      canCreate={false}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        onRowClick={(params) => {
          navigate(`${basePath}/show/${params.row.id}`);
        }}
        localeText={{
          noRowsLabel: 'Aucun résultat',
        }}
        density="compact"
      />
    </List>
  );
};
