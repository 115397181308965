// disable typescript eslint checks for this file
import { useList, useUpdate } from "@pankod/refine-core";
import {
  Box,
  Button,
  FormLabel,
  Show,
  Switch,
  TextField,
  Typography,
} from "@pankod/refine-mui";
import { useState } from "react";

type IPropsType = {
  item?: any;
  starter: string;
  starterPrice: string;
  main: string;
  mainPrice: string;
  dessert: string;
  dessertPrice: string;
};

export const SpecialsEdit = () => {
  const [menu, setMenu] = useState({
    starter: "",
    starterPrice: "",
    main: "",
    mainPrice: "",
    dessert: "",
    dessertPrice: "",
  });
  const { data, isLoading } = useList<any>({
    resource: "specials",
    config: {
      hasPagination: false,
    },
  });

  const record = data?.data[0];

  const mutationResult = useUpdate<any>();

  const { mutate, isLoading: mutateIsLoading } = mutationResult;

  const handleUpdate = ({
    item,
    starter,
    starterPrice,
    main,
    mainPrice,
    dessert,
    dessertPrice,
  }: IPropsType) => {
    mutate({
      resource: "specials",
      id: 1,
      values: {
        ...item,
        starter,
        starterPrice,
        main,
        mainPrice,
        dessert,
        dessertPrice,
      },
    });
  };

  const handleDisplay = (item: any, active: boolean) => {
    mutate({
      resource: "specials",
      id: 1,
      values: { ...item, active },
    });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }
  console.log(record?.active);
  return (
    <Show
      title={
        <Typography variant="h6">Modifier les suggestions du jour</Typography>
      }
      wrapperProps={{
        sx: {
          marginTop: "3rem",
        },
      }}
      resource="specials"
      isLoading={isLoading}
      goBack={false}
      canEdit={false}
      recordItemId={record?.id}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <Typography fontSize="1rem" fontWeight="600">
          Entrée du jour :
        </Typography>
        <TextField
          name="starter"
          defaultValue={record?.starter}
          multiline
          maxRows={3}
          margin="normal"
          fullWidth
          onChange={(e) => setMenu({ ...menu, starter: e.target.value })}
          id="starter"
        />
        <Typography fontSize="1rem" fontWeight="600">
          Prix de l'entrée :
        </Typography>
        <TextField
          name="starterPrice"
          label="Prix"
          defaultValue={record?.starterPrice}
          margin="normal"
          fullWidth
          onChange={(e) => setMenu({ ...menu, starterPrice: e.target.value })}
          id="starterPrice"
        />
        <Typography fontSize="1rem" fontWeight="600">
          Plat du jour :
        </Typography>
        <TextField
          name="main"
          defaultValue={record?.main}
          multiline
          maxRows={4}
          margin="normal"
          fullWidth
          onChange={(e) => setMenu({ ...menu, main: e.target.value })}
          id="main"
        />
        <Typography fontSize="1rem" fontWeight="600">
          Prix du plat :
        </Typography>
        <TextField
          name="mainPrice"
          label="Prix"
          defaultValue={record?.mainPrice}
          margin="normal"
          fullWidth
          onChange={(e) => setMenu({ ...menu, mainPrice: e.target.value })}
          id="mainPrice"
        />

        <Typography fontSize="1rem" fontWeight="600">
          Dessert du jour :
        </Typography>
        <TextField
          name="dessert"
          defaultValue={record?.dessert}
          multiline
          maxRows={3}
          margin="normal"
          fullWidth
          onChange={(e) => setMenu({ ...menu, dessert: e.target.value })}
          id="dessert"
        />
        <Typography fontSize="1rem" fontWeight="600">
          Prix du dessert :
        </Typography>
        <TextField
          name="dessertPrice"
          label="Prix"
          defaultValue={record?.dessertPrice}
          margin="normal"
          fullWidth
          onChange={(e) => setMenu({ ...menu, dessertPrice: e.target.value })}
          id="dessertPrice"
        />
        <FormLabel component="legend">
          <Typography fontSize="1rem" fontWeight="600" marginTop="1rem">
            Activer l'affichage des suggestions :
          </Typography>
        </FormLabel>
        <Switch
          name="activate"
          checked={record?.active}
          onChange={(e) => {
            if (e.target.checked) {
              handleDisplay(record?.active, true);
            }
            if (!e.target.checked) {
              handleDisplay(record?.active, false);
            }
          }}
        />
      </Box>
      <Box marginTop="1rem">
        <Button
          variant="contained"
          onClick={() => {
            handleUpdate({
              // ignore if state is empty
              starter: menu.starter || record?.starter,
              starterPrice: menu.starterPrice || record?.starterPrice,
              main: menu.main || record?.main,
              mainPrice: menu.mainPrice || record?.mainPrice,
              dessert: menu.dessert || record?.dessert,
              dessertPrice: menu.dessertPrice || record?.dessertPrice,
            });
          }}
        >
          Mettre à jour
        </Button>
      </Box>
    </Show>
  );
};
