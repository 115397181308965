import * as React from 'react';
import { useForm } from '@pankod/refine-react-hook-form';
import {
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Box,
  Container,
  Card,
  CardContent,
  Typography,
} from '@pankod/refine-mui';

import { styled } from '@mui/material/styles';

import { BaseRecord, HttpError, useLogin, useTranslate } from '@pankod/refine-core';
import { useNavigate } from 'react-router-dom';

type ILoginForm = {
  username: string;
  password: string;
  remember?: boolean;
  // const { replace } = useNavigation();
  replace: (path: string) => void;
};

export const LoginPage: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<BaseRecord, HttpError, ILoginForm>({
    defaultValues: {
      remember: false,
      username: '',
      password: '',
    },
  });

  const navigate = useNavigate();
  const { mutate: login, isLoading } = useLogin<ILoginForm>({
    mutationOptions: {
      onSettled: () => {
        navigate('/');
      },
    },
  });
  const translate = useTranslate();

  const CssTextField = styled(TextField)({
    '& .MuiInputBase-root': {
      color: 'black',
    },
    '& label': {
      color: 'black',
    },
    '& label.Mui-focused': {
      color: 'black',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'lack',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'black',
      },
      '&:hover fieldset': {
        borderColor: 'black',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  });

  return (
    <>
      <Box
        component="div"
        sx={{
          backgroundColor: '#4f7994',
        }}
      >
        <Container
          component="main"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Box maxWidth="400px" mt={4}>
              <Card
                sx={{
                  padding: 1,
                  background: 'white',
                  border: '1px solid white',
                  borderRadius: 'unset',
                  boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.25)',
                }}
              >
                <CardContent>
                  <Box
                    sx={{
                      width: { xs: 200, lg: 'auto' },
                      marginLeft: { xs: 'auto', lg: 'unset' },
                      marginRight: { xs: 'auto', lg: 'unset' },
                    }}
                  >
                    <img src="/refine-black.svg" alt="logoCafé Hamlet" style={{ width: '100%' }} />
                    <Typography
                      variant={'h4'}
                      sx={{ textAlign: 'center', color: 'black', fontSize: '20px' }}
                    >
                      Connexion
                    </Typography>
                  </Box>
                  <Box
                    component="form"
                    onSubmit={handleSubmit((data) => {
                      login(data);
                    })}
                  >
                    <CssTextField
                      {...register('username', {
                        required: true,
                      })}
                      id="username"
                      margin="normal"
                      fullWidth
                      label={translate('pages.login.username', "Nom d'utilisateur")}
                      name="username"
                      autoComplete="username"
                    />
                    <CssTextField
                      {...register('password', {
                        required: true,
                      })}
                      id="password"
                      margin="normal"
                      fullWidth
                      label={translate('pages.login.password', 'Mot de passe')}
                      helperText={errors?.password?.message}
                      type="password"
                      autoComplete="current-password"
                    />
                    <Box
                      component="div"
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}
                    >
                      <FormControlLabel
                        sx={{
                          span: {
                            fontSize: '12px',
                            color: 'black',
                          },
                        }}
                        color="secondary"
                        control={
                          <Checkbox
                            size="small"
                            id="remember"
                            sx={{
                              '&.Mui-checked': {
                                color: 'black',
                              },
                            }}
                            {...register('remember')}
                          />
                        }
                        label={translate('pages.login.remember', 'Se souvenir de moi')}
                      />
                      {/* <Link
                        href="#"
                        sx={{
                          color: "black",
                          textDecoration: "none",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "12px",
                          }}
                        >
                          {translate("pages.login.forgotPassword", "Forgot?")}
                        </Typography>
                      </Link> */}
                    </Box>
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      sx={{
                        my: '8px',
                        color: 'black',
                        background: 'transparent',
                        borderRadius: 'unset',
                        boxShadow: 'none',
                        border: '1px solid black',
                        '&:hover': {
                          background: 'transparent',
                        },
                      }}
                      disabled={isLoading}
                    >
                      {translate('pages.login.signin', 'Se connecter')}
                    </Button>
                    {/* <Link
                      href="#"
                      sx={{
                        textDecoration: "none",
                        display: "flex",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "12px",
                          letterSpacing: "-0.04px",
                          lineHeight: "38px",
                          color: "black",
                        }}
                      >
                        {translate(
                          "pages.login.noAccount",
                          "Don’t have an account? "
                        )}{" "}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: "700",
                          fontSize: "12px",
                          letterSpacing: "-0.04px",
                          lineHeight: "38px",
                          color: "primary.main",
                          marginLeft: "2px",
                        }}
                      >
                        {translate("pages.login.signup", " Sign up")}
                      </Typography>
                    </Link> */}
                  </Box>
                </CardContent>
              </Card>
            </Box>
          </Box>
        </Container>
      </Box>
    </>
  );
};
