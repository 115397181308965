import React from 'react';
import { useDataGrid, DataGrid, GridColumns, List, Typography } from '@pankod/refine-mui';
import { useNavigate } from 'react-router-dom';
import { IUnblockeds } from '../../interfaces';

export const UnblockedsList: React.FC = () => {
  const basePath = window.location.pathname;
  const { dataGridProps } = useDataGrid<IUnblockeds>({
    initialSorter: [{ field: 'date', order: 'desc' }],
  });

  const navigate = useNavigate();

  const columns = React.useMemo<GridColumns<IUnblockeds>>(
    () => [
      {
        field: 'date',
        headerName: 'Date',
        valueGetter(params) {
          return params.row.id;
        },
        renderCell: (params) => (
          <Typography fontSize={14} style={{ cursor: 'pointer' }}>
            {params.row.date &&
              new Date(params.row.date).toLocaleDateString('fr-FR', {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
              })}
          </Typography>
        ),
        flex: 1,
      },
    ],
    []
  );

  return (
    <List
      createButtonProps={{
        children: 'Ajouter une date',
        sx: { marginTop: '1rem' },
      }}
      wrapperProps={{
        sx: {
          marginTop: '3rem',
        },
      }}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        // go to edit view onClick
        onRowClick={(params) => {
          navigate(`${basePath}/edit/${params.row.id}`);
        }}
        localeText={{
          noRowsLabel: 'Aucun jour débloqué pour le moment',
        }}
        autoHeight
        density="compact"
      />
    </List>
  );
};
