import React from 'react';
import { useDataGrid, DataGrid, GridColumns, List } from '@pankod/refine-mui';

import { IGiftCards } from 'interfaces';
import { useNavigate } from 'react-router-dom';

export const GiftCardsList: React.FC = () => {
  const navigate = useNavigate();
  // get base path from router
  const basePath = window.location.pathname;
  const { dataGridProps } = useDataGrid<IGiftCards>({
    initialSorter: [{ field: 'createdAt', order: 'desc' }],
  });

  const columns = React.useMemo<GridColumns<IGiftCards>>(
    () => [
      {
        field: 'name',
        headerName: 'Nom',
        flex: 0.5,
      },
      {
        field: 'amount',
        headerName: 'Solde',
        flex: 0.2,
        // add € symbol
        renderCell: (params) => `${params.value} €`,
      },
      {
        field: 'giftCardId',
        headerName: 'ID',
      },
      {
        field: 'used',
        headerName: 'Utilisée',
        flex: 0.5,
        // if true display "Oui" else "Non"
        renderCell: (params) => (params.value ? 'Oui' : 'Non'),
      },
    ],
    []
  );

  return (
    <List
      createButtonProps={{ children: 'Ajouter' }}
      wrapperProps={{
        sx: {
          marginTop: '3rem',
        },
      }}
      canCreate={false}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        onRowClick={(params) => {
          navigate(`${basePath}/show/${params.row.id}`);
        }}
        localeText={{
          noRowsLabel: 'Aucun résultat',
        }}
        density="compact"
      />
    </List>
  );
};
