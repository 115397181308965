import React, { Fragment } from 'react';
import {
  useDataGrid,
  DataGrid,
  GridColumns,
  List,
  Typography,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
  TextField,
  Grid,
} from '@pankod/refine-mui';
import dayjs from 'dayjs';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { useNavigate } from 'react-router-dom';

import { IBookings } from 'interfaces';
import fr from 'date-fns/locale/fr';

export const BookingsList: React.FC = () => {
  const [value, setValue] = React.useState('');
  const [pickedDate, setPickedDate] = React.useState<Date>(new Date());
  const [showPicker, setShowPicker] = React.useState(false);
  const { dataGridProps, setFilters, pageSize } = useDataGrid<IBookings>({
    initialSorter: [
      {
        field: 'date',
        order: 'asc',
      },
    ],
    // most recent dates first
    initialCurrent: 1,
    initialPageSize: 20,
  });

  const navigate = useNavigate();

  const columns = React.useMemo<GridColumns<IBookings>>(
    () => [
      {
        field: 'time',
        headerName: 'Heure',
        maxWidth: 60,
        valueGetter(params) {
          return params.row.id;
        },
        // render title with link to show page
        renderCell: (params) => (
          <Typography fontSize={14} style={{ cursor: 'pointer' }}>
            {params.row.time}
          </Typography>
        ),
        flex: 1,
      },
      {
        field: 'partySize',
        headerName: 'N',

        valueGetter(params) {
          return params.row.id;
        },
        // render title with link to show page
        renderCell: (params) => (
          <Typography fontSize={14} style={{ cursor: 'pointer' }}>
            {params.row.partySize}
          </Typography>
        ),
        flex: 0.2,
      },
      {
        field: 'name',
        headerName: 'Nom',
        valueGetter(params) {
          return params.row.id;
        },
        // render title with link to show page
        renderCell: (params) => (
          <Typography fontSize={14} style={{ cursor: 'pointer' }}>
            {params.row.name}
          </Typography>
        ),
        flex: 1,
      },
      {
        field: 'date',
        headerName: 'Date',
        // show date in french format

        // if title is empty, show value from createdAt field
        renderCell: (params) => (
          <Typography fontSize={14} style={{ cursor: 'pointer' }}>
            {new Date(params.row.date).toLocaleDateString('fr-FR', {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
            })}
          </Typography>
        ),
        flex: 1,
      },
    ],
    [navigate]
  );
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    if (event.target.value === 'today') {
      setFilters([
        {
          field: 'date',
          value: `${dayjs().format('YYYY-MM-DD')}&sort=time:asc`,
          operator: 'eq',
        },
      ]);
      setShowPicker(false);
    } else if (event.target.value === 'tomorrow') {
      setFilters([
        {
          field: 'date',
          value: `${dayjs().add(1, 'day').format('YYYY-MM-DD')}&sort=time:asc`,
          operator: 'eq',
        },
      ]);
      setShowPicker(false);
    }
    if (event.target.value === 'all') {
      setFilters([]);
      setShowPicker(false);
    }
    if (event.target.value === 'other') {
      setShowPicker(true);
    }
  };
  registerLocale('fr', fr);
  const basePath = window.location.pathname;
  return (
    <List
      createButtonProps={{
        children: 'Ajouter',
        sx: { marginTop: '1rem' },
      }}
      wrapperProps={{
        sx: {
          marginTop: '3rem',
        },
      }}
    >
      <FormControl
        sx={{
          marginTop: '1rem',
          marginBottom: '1rem',
          paddingBottom: '1rem',
        }}
      >
        <Fragment>
          <FormLabel>Filtrer par date</FormLabel>
          <RadioGroup
            row
            onChange={handleChange}
            value={value}
            sx={{
              marginTop: '1rem',
            }}
          >
            <FormControlLabel value="all" control={<Radio />} label="Toutes" />
            <FormControlLabel value="today" control={<Radio />} label="Aujourd'hui" />
            <FormControlLabel value="tomorrow" control={<Radio />} label="Demain" />
            <FormControlLabel value="other" control={<Radio />} label="Autre date" />
          </RadioGroup>
          {showPicker && (
            <DatePicker
              selected={pickedDate}
              dateFormat="dd/MM/yyyy"
              onSelect={(date: Date) => {
                setPickedDate(date);
                setFilters([
                  {
                    field: 'date',
                    value: `${dayjs(date).format('YYYY-MM-DD')}&sort=time:asc`,
                    operator: 'eq',
                  },
                ]);
              }}
              locale="fr"
              onChange={(date: Date) => {
                setPickedDate(date);
                setFilters([
                  {
                    field: 'date',
                    value: `${dayjs(date).format('YYYY-MM-DD')}&sort=time:asc`,
                    operator: 'eq',
                  },
                ]);
              }}
              customInput={<TextField size="small" />}
            />
          )}
        </Fragment>
      </FormControl>
      <FormControl
        sx={{
          marginTop: '1rem',
          marginBottom: '1rem',
          paddingBottom: '1rem',
        }}
      >
        <FormLabel>Filtrer par nom</FormLabel>
        <Grid
          sx={{
            marginTop: '1rem',
          }}
        >
          <TextField
            variant="outlined"
            size="small"
            label="Nom"
            onChange={(e) =>
              setFilters([
                {
                  field: 'name',
                  value: e.target.value,
                  operator: 'containss',
                },
              ])
            }
          />
        </Grid>
      </FormControl>
      <DataGrid
        {...dataGridProps}
        columns={columns}
        pageSize={pageSize}
        autoHeight
        columnThreshold={2}
        disableExtendRowFullWidth
        onRowClick={(params) => {
          navigate(`${basePath}/edit/${params.row.id}`);
        }}
        localeText={{
          noRowsLabel: 'Aucune réservation trouvée',
          noResultsOverlayLabel: 'Aucune réservation trouvée',
        }}
        density="compact"
      />
    </List>
  );
};
