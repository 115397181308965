import React from 'react';
import { useDataGrid, DataGrid, GridColumns, List, Typography } from '@pankod/refine-mui';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { IBlockeds } from 'interfaces';
import { useNavigate } from 'react-router-dom';

export const BlockedsList: React.FC = () => {
  const basePath = window.location.pathname;
  const { dataGridProps } = useDataGrid<IBlockeds>({
    initialSorter: [{ field: 'date', order: 'desc' }],
  });

  const navigate = useNavigate();

  const columns = React.useMemo<GridColumns<IBlockeds>>(
    () => [
      {
        field: 'date',
        headerName: 'Date',
        valueGetter(params) {
          return params.row.id;
        },
        renderCell: (params) => (
          <Typography fontSize={14} style={{ cursor: 'pointer' }}>
            {params.row.date &&
              new Date(params.row.date).toLocaleDateString('fr-FR', {
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
              })}
          </Typography>
        ),
        flex: 1,
      },
      {
        field: 'lunch',
        headerName: 'Midi bloqué',
        valueGetter(params) {
          return params.row.id;
        },
        renderCell: (params) => (
          <Typography fontSize={14} style={{ cursor: 'pointer' }}>
            {params.row.lunch ? (
              <CheckIcon
                sx={{
                  color: 'red',
                }}
              />
            ) : (
              <CloseIcon
                sx={{
                  color: 'green',
                }}
              />
            )}
          </Typography>
        ),
        flex: 1,
      },
      {
        field: 'evening',
        headerName: 'Soirée bloquée',
        valueGetter(params) {
          return params.row.id;
        },
        renderCell: (params) => (
          <Typography fontSize={14} style={{ cursor: 'pointer' }}>
            {params.row.evening ? (
              <CheckIcon
                sx={{
                  color: 'red',
                }}
              />
            ) : (
              <CloseIcon
                sx={{
                  color: 'green',
                }}
              />
            )}
          </Typography>
        ),
        flex: 1,
      },
    ],
    []
  );

  return (
    <List
      createButtonProps={{
        children: 'Ajouter une date',
        sx: { marginTop: '1rem' },
      }}
      wrapperProps={{
        sx: {
          marginTop: '3rem',
        },
      }}
    >
      <DataGrid
        {...dataGridProps}
        columns={columns}
        // go to edit view onClick
        onRowClick={(params) => {
          navigate(`${basePath}/edit/${params.row.id}`);
        }}
        localeText={{
          noRowsLabel: 'Aucun jour bloqué pour le moment',
        }}
        autoHeight
        density="compact"
      />
    </List>
  );
};
