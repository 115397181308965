import { useList, useShow } from '@pankod/refine-core';
import {
  Show,
  Stack,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from '@pankod/refine-mui';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { IOrders } from 'interfaces';
import { Fragment, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';

export const OrdersShow: React.FC = () => {
  const { queryResult } = useShow<IOrders>({});

  const { data, isLoading } = queryResult;
  const record = data?.data;

  const { data: giftCard } = useList<any>({
    resource: 'giftcards',
    config: {
      hasPagination: false,
    },
  });

  const giftCardData = giftCard?.data;

  const [matchingIds, setMatchingIds] = useState<any>([
    {
      giftCardId: '123',
      'giftCard.giftCardId': '123',
      'giftCard.used': false,
    },
  ]);

  const navigate = useNavigate();

  useEffect(() => {
    // find ids for which giftCardId is equal to result and assign to matchingIds,
    // each match must be assigned to the corresponding giftCard in the order
    // example: order contains 2 giftCards, each giftCard has a giftCardId
    // if the giftCardId is found in the giftCards list, then the id of the giftCard
    // is assigned to the matchingIds array
    // [{
    //      giftCardId: '123',
    //      giftCard.giftCardId: '123',
    //      giftCard.used: false // or true
    // }]

    const matchingIds = record?.content?.giftCards.map((giftCard) => {
      const matchingId = giftCardData?.find(
        (item: any) => item.giftCardId === giftCard.giftCardId
      )?.id;
      const used = giftCardData?.find((item: any) => item.giftCardId === giftCard.giftCardId)?.used;

      return {
        giftCardId: giftCard.giftCardId,
        'giftCard.giftCardId': matchingId,
        'giftCard.used': used,
      };
    });

    setMatchingIds(matchingIds);
  }, [record, giftCardData]);

  return (
    <Show
      title={<Typography variant="h6">Détails de la commande</Typography>}
      isLoading={isLoading}
      wrapperProps={{
        sx: {
          marginTop: '3rem',
        },
      }}
    >
      <Stack gap={1}>
        <Typography variant="body1" fontWeight="bold">
          Nom du client
        </Typography>
        <Typography variant="body2">{record?.content?.customerName}</Typography>
        {/*<Typography variant="body1" fontWeight="bold">*/}
        {/*  Tél :*/}
        {/*</Typography>*/}
        {/*<Typography variant="body2">{record?.phone}</Typography>*/}
        <Typography variant="body1" fontWeight="bold">
          Email
        </Typography>
        <Typography variant="body2">{record?.email}</Typography>
        <Typography variant="body1" fontWeight="bold">
          Montant total
        </Typography>
        <Typography variant="body2">{record?.total}€</Typography>
        <Typography variant="body1" fontWeight="bold">
          ID de la commande
        </Typography>
        <Typography variant="body2">{record?.orderId}</Typography>
        <Typography variant="body1" fontWeight="bold">
          Date d'achat
        </Typography>
        <Typography variant="body2">
          {new Date(record?.createdAt!).toLocaleDateString('fr-FR', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
          })}
        </Typography>
        <Typography
          variant="body1"
          fontWeight="bold"
          sx={{
            marginTop: '1rem',
            marginBottom: '1rem',
          }}
        >
          Contenu de la commande :
        </Typography>

        {record?.content?.giftCards.map((giftCard) => (
          <Accordion key={giftCard.giftCardId}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Stack alignItems={'center'} display={'flex'}>
                <Typography variant="body1" fontWeight="bold">
                  Montant de la carte
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    marginTop: '0.5rem',
                  }}
                >
                  {giftCard.amount}€
                </Typography>
              </Stack>
            </AccordionSummary>
            <AccordionDetails>
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{
                  marginTop: '0.5rem',
                }}
              >
                À l'intention de
              </Typography>
              <Typography variant="body2">{giftCard.to}</Typography>

              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{
                  marginTop: '0.5rem',
                }}
              >
                ID de la carte
              </Typography>
              <Typography variant="body2">{giftCard.giftCardId}</Typography>
              <Typography
                variant="body1"
                fontWeight="bold"
                sx={{
                  marginTop: '0.7rem',
                }}
              >
                ID du paiement
              </Typography>
              <Typography variant="body2">{giftCard.stripe_payment_id}</Typography>
              <Typography
                variant="body2"
                sx={{
                  marginTop: '0.7rem',
                }}
              >
                {matchingIds?.map((id: any) => {
                  if (id.giftCardId === giftCard.giftCardId) {
                    return (
                      <Fragment key={id['giftCard.giftCardId']}>
                        <Typography
                          variant={id['giftCard.used'] ? 'body2' : 'body1'}
                          sx={{
                            color: 'white',
                            marginBottom: '0.8rem',
                            bgcolor: id['giftCard.used'] ? 'red' : 'green',
                            width: 'fit-content',
                            paddingLeft: '0.5rem',
                            paddingRight: '0.5rem',
                            fontWeight: 'bold',
                          }}
                        >
                          {id['giftCard.used'] ? 'Carte utilisée' : 'Carte non utilisée'}
                        </Typography>
                        <Button
                          variant={'contained'}
                          color={'primary'}
                          fullWidth={true}
                          onClick={() => {
                            navigate(`/giftcards/show/${id['giftCard.giftCardId']}`);
                          }}
                          sx={{
                            textAlign: 'left',
                            width: 'fit-content',
                          }}
                          startIcon={<CardGiftcardIcon />}
                        >
                          Voir la carte
                        </Button>
                      </Fragment>
                    );
                  } else {
                    return null;
                  }
                })}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Stack>
    </Show>
  );
};
