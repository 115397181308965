// disable typescript eslint checks for this file
import { useList, useUpdate } from "@pankod/refine-core";
import {
  Box,
  Button,
  FormLabel,
  Show,
  Switch,
  TextField,
  Typography,
} from "@pankod/refine-mui";
import { useState } from "react";

export const UserNotifsEdit = () => {
  const [messageValue, setMessageValue] = useState<string>("");
  const { data, isLoading } = useList<any>({
    resource: "usernotifs",
    config: {
      hasPagination: false,
    },
  });

  const record = data?.data[0];

  const mutationResult = useUpdate<any>();

  const { mutate, isLoading: mutateIsLoading } = mutationResult;

  const handleUpdate = (item: any, message: any) => {
    mutate({
      resource: "usernotifs",
      id: 1,
      values: { ...item, message },
    });
  };

  const handleDisplay = (item: any, activate: any) => {
    mutate({
      resource: "usernotifs",
      id: 1,
      values: { ...item, activate },
    });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <Show
      title={
        <Typography variant="h6">Modifier message d'information</Typography>
      }
      wrapperProps={{
        sx: {
          marginTop: "3rem",
        },
      }}
      resource="usernotifs"
      isLoading={isLoading}
      goBack={false}
      canEdit={false}
      recordItemId={record?.id}
    >
      <Box
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
        autoComplete="off"
      >
        <FormLabel component="legend">
          <Typography fontSize="1rem" fontWeight="600" marginBottom="1rem">
            Message à afficher :
          </Typography>
        </FormLabel>
        <TextField
          name="message"
          label="Message"
          defaultValue={record?.message}
          margin="normal"
          multiline
          rows={5}
          fullWidth
          onChange={(e) => setMessageValue(e.target.value)}
          id="message"
        />
      </Box>

      <Box>
        <FormLabel component="legend">
          <Typography fontSize="1rem" fontWeight="600" marginTop="1rem">
            Activer l'affichage du message :
          </Typography>
        </FormLabel>
        <Switch
          name="activate"
          checked={record?.activate}
          onChange={(e) => {
            if (e.target.checked) {
              handleDisplay(record?.activate, true);
            }
            if (!e.target.checked) {
              handleDisplay(record?.activate, false);
            }
          }}
        />
      </Box>
      <Box marginTop="1rem">
        <Button
          variant="contained"
          onClick={() => {
            handleUpdate(record?.message, messageValue);
          }}
        >
          Enregistrer le message
        </Button>
      </Box>
    </Show>
  );
};
