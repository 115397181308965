import { Refine, Authenticated } from '@pankod/refine-core';
import {
  notificationProvider,
  RefineSnackbarProvider,
  CssBaseline,
  GlobalStyles,
  ReadyPage,
  ErrorComponent,
} from '@pankod/refine-mui';
import ChildCareIcon from '@mui/icons-material/ChildCare';
import FlatwareIcon from '@mui/icons-material/Flatware';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import BookIcon from '@mui/icons-material/Book';
import DangerousIcon from '@mui/icons-material/Dangerous';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import LiquorIcon from '@mui/icons-material/Liquor';
import WineBarIcon from '@mui/icons-material/WineBar';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import routerProvider from '@pankod/refine-react-router-v6';
import { DataProvider } from '@pankod/refine-strapi-v4';
import { RefineKbarProvider } from '@pankod/refine-kbar';
import { useTranslation } from 'react-i18next';
import { ColorModeContextProvider } from 'contexts';
import { Title, Sider, Layout, Header } from 'components/layout';
import { OffLayoutArea } from 'components/offLayoutArea';
import { authProvider, axiosInstance } from './authProvider';
import { API_URL } from './constants';
import { LoginPage } from 'pages/login';
import { BookingsList } from 'pages/booking/list';
import { BookingsEdit } from 'pages/booking/edit';
import { BookingsCreate } from 'pages/booking/create';
import { BlockedsList } from 'pages/blocked/list';
import { BlockedsEdit } from 'pages/blocked/edit';
import { HolidaysList } from 'pages/holiday/list';
import { HolidaysEdit } from 'pages/holiday/edit';
import { HolidaysCreate } from 'pages/holiday/create';
import { BlockedsCreate } from 'pages/blocked/create';
import { UserNotifsEdit } from 'pages/usernotif/edit';
import { FoodsList } from 'pages/food/list';
import { FoodsEdit } from 'pages/food/edit';
import { FoodsCreate } from 'pages/food/create';
import { WinesList } from 'pages/wines/list';
import { WinesEdit } from 'pages/wines/edit';
import { WinesCreate } from 'pages/wines/create';
import { GiftCardsList } from 'pages/giftCard/list';
import { GiftCardsReader } from 'pages/giftCardsReader';
import { GiftCardsShow } from 'pages/giftCard/show';
import { SpecialsEdit } from 'pages/special/edit';
import { Dashboard } from 'components/dashboard';
import { OrdersList } from './pages/orders/list';
import { OrdersShow } from './pages/orders/show';
import { UnblockedsCreate } from './pages/unblocked/create';
import { UnblockedsList } from './pages/unblocked/list';
import { UnblockedsEdit } from './pages/unblocked/edit';

const UserNotifications = () => {
  return (
    <Authenticated>
      <UserNotifsEdit />
    </Authenticated>
  );
};

const Specials = () => {
  return (
    <Authenticated>
      <SpecialsEdit />
    </Authenticated>
  );
};

const GiftCardsScanner = () => {
  return (
    <Authenticated>
      <GiftCardsReader />
    </Authenticated>
  );
};

const DashboardPage = () => {
  return (
    <Authenticated>
      <Dashboard />
    </Authenticated>
  );
};

function App() {
  const { t, i18n } = useTranslation();

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };
  const strapiDataProvider = DataProvider(API_URL + `/api`, axiosInstance as any);

  return (
    <ColorModeContextProvider>
      <CssBaseline />
      <GlobalStyles styles={{ html: { WebkitFontSmoothing: 'auto' } }} />
      <RefineSnackbarProvider>
        <RefineKbarProvider>
          <Refine
            notificationProvider={notificationProvider}
            ReadyPage={ReadyPage}
            DashboardPage={DashboardPage}
            LoginPage={LoginPage}
            catchAll={<ErrorComponent />}
            routerProvider={{
              ...routerProvider,
              routes: [
                {
                  element: <UserNotifications />,
                  path: '/usernotifs',
                  layout: true,
                },
                {
                  element: <GiftCardsScanner />,
                  path: '/giftcardsreader',
                  layout: true,
                },
                {
                  element: <Specials />,
                  path: '/specials',
                  layout: true,
                },
              ],
            }}
            authProvider={authProvider}
            dataProvider={{
              default: strapiDataProvider,
            }}
            Title={Title}
            Sider={Sider}
            Layout={Layout}
            Header={Header}
            OffLayoutArea={OffLayoutArea}
            i18nProvider={i18nProvider}
            resources={[
              {
                name: 'bookings',
                list: BookingsList,
                edit: BookingsEdit,
                create: BookingsCreate,
                icon: <BookIcon />,
                options: {
                  label: 'Réservations',
                },
              },
              {
                name: 'Dates',
                icon: <SettingsApplicationsIcon />,
              },
              {
                name: 'blockeds',
                list: BlockedsList,
                edit: BlockedsEdit,
                create: BlockedsCreate,
                icon: <DangerousIcon />,
                parentName: 'Dates',
                options: {
                  label: 'Jours bloqués',
                },
              },
              {
                name: 'unblockeds',
                create: UnblockedsCreate,
                list: UnblockedsList,
                edit: UnblockedsEdit,
                icon: <TaskAltIcon />,
                parentName: 'Dates',
                options: {
                  label: 'Jours débloqués',
                },
              },
              {
                name: 'holidays',
                list: HolidaysList,
                edit: HolidaysEdit,
                create: HolidaysCreate,
                parentName: 'Dates',
                icon: <DangerousIcon />,
                options: {
                  label: 'Périodes bloquées',
                },
              },
              {
                name: 'usernotifs',
                list: UserNotifsEdit,
                icon: <NotificationsNoneIcon />,
                options: {
                  label: 'Notifications visiteurs',
                  path: '/usernotifs',
                },
              },
              {
                name: 'specials',
                list: SpecialsEdit,
                icon: <RestaurantIcon />,
                options: {
                  label: 'Suggestions du jour',
                  path: '/specials',
                },
              },
              {
                name: 'giftcards',
                list: GiftCardsList,
                show: GiftCardsShow,
                icon: <CardGiftcardIcon />,
                options: {
                  label: 'Cartes cadeaux',
                },
              },
              {
                name: 'orders',
                list: OrdersList,
                show: OrdersShow,
                options: {
                  label: 'Commandes',
                },
              },
              {
                name: 'giftcardsreader',
                list: GiftCardsScanner,
                icon: <QrCodeScannerIcon />,
                options: {
                  label: 'Scanner une carte cadeau',
                  path: '/giftscardreader',
                },
              },
              {
                name: 'Menus',
                icon: <FlatwareIcon />,
              },
              {
                name: 'aperospartages',
                list: FoodsList,
                edit: FoodsEdit,
                create: FoodsCreate,
                icon: <FlatwareIcon />,
                parentName: 'Menus',
                options: {
                  label: 'Apero Partages',
                },
              },
              {
                name: 'starters',
                list: FoodsList,
                edit: FoodsEdit,
                create: FoodsCreate,
                icon: <FlatwareIcon />,
                parentName: 'Menus',
                options: {
                  label: 'Les entrées',
                },
              },
              {
                name: 'mains',
                list: FoodsList,
                edit: FoodsEdit,
                create: FoodsCreate,
                icon: <FlatwareIcon />,
                parentName: 'Menus',
                options: {
                  label: 'Les plats',
                },
              },
              {
                name: 'douceurs',
                list: FoodsList,
                edit: FoodsEdit,
                create: FoodsCreate,
                icon: <FlatwareIcon />,
                parentName: 'Menus',
                options: {
                  label: 'Les douceurs',
                },
              },
              {
                name: 'childmenus',
                list: FoodsList,
                edit: FoodsEdit,
                create: FoodsCreate,
                icon: <ChildCareIcon />,
                parentName: 'Menus',
                options: {
                  label: 'Menu enfant',
                },
              },
              {
                name: 'Vins',
                icon: <LiquorIcon />,
              },
              {
                name: 'reds',
                list: WinesList,
                edit: WinesEdit,
                create: WinesCreate,
                parentName: 'Vins',
                icon: <WineBarIcon />,
                options: {
                  label: 'Vins rouges',
                },
              },
              {
                name: 'roses',
                list: WinesList,
                edit: WinesEdit,
                create: WinesCreate,
                parentName: 'Vins',
                icon: <WineBarIcon />,
                options: {
                  label: 'Vins rosés',
                },
              },
              {
                name: 'whites',
                list: WinesList,
                edit: WinesEdit,
                create: WinesCreate,
                parentName: 'Vins',
                icon: <WineBarIcon />,
                options: {
                  label: 'Vins blancs',
                },
              },
            ]}
          />
        </RefineKbarProvider>
      </RefineSnackbarProvider>
    </ColorModeContextProvider>
  );
}

export default App;
