import React, { useState } from 'react';
import {
  useDataGrid,
  DataGrid,
  GridColumns,
  List,
  Stack,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@pankod/refine-mui';

import { IFoods } from 'interfaces';
import { useNavigate } from 'react-router-dom';

export const FoodsList: React.FC = () => {
  const [locale, setLocale] = useState('fr');
  const navigate = useNavigate();
  // get base path from router
  const basePath = window.location.pathname;
  const { dataGridProps } = useDataGrid<IFoods>({
    metaData: {
      locale,
    },
    initialSorter: [{ field: 'createdAt', order: 'desc' }],
  });

  const columns = React.useMemo<GridColumns<IFoods>>(
    () => [
      {
        field: 'title',
        headerName: 'Titre',
        flex: 1,
      },
      {
        field: 'price',
        headerName: 'Prix',
      },
    ],
    []
  );
  return (
    <List
      createButtonProps={{ children: 'Ajouter' }}
      wrapperProps={{
        sx: {
          marginTop: '3rem',
        },
      }}
    >
      <Stack direction="row" alignItems={'center'} spacing={2}>
        <FormLabel>Langue</FormLabel>

        <RadioGroup row defaultValue={locale}>
          <FormControlLabel
            value="fr"
            control={<Radio />}
            label="FR"
            onClick={() => setLocale('fr')}
          />
          <FormControlLabel
            value="en"
            control={<Radio />}
            label="EN"
            onClick={() => setLocale('en')}
          />
        </RadioGroup>
      </Stack>
      <DataGrid
        {...dataGridProps}
        columns={columns}
        autoHeight
        onRowClick={(params) => {
          navigate(`${basePath}/edit/${params.row.id}`);
        }}
        localeText={{
          noRowsLabel: 'Aucun résultat',
        }}
        density="compact"
      />
    </List>
  );
};
