import { HttpError } from '@pankod/refine-core';
import { Edit, Box, Typography, Switch, FormLabel, TextField } from '@pankod/refine-mui';
import { Controller, useForm } from '@pankod/refine-react-hook-form';
import fr from 'date-fns/locale/fr';
import { registerLocale } from 'react-datepicker';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';
import { IUnblockeds } from '../../interfaces';

export const UnblockedsEdit: React.FC = () => {
  registerLocale('fr', fr);
  const {
    refineCore: { formLoading },
    saveButtonProps,
    control,
  } = useForm<IUnblockeds, HttpError, IUnblockeds>({
    refineCoreProps: {
      metaData: {
        populate: '*',
      },
    },
  });
  return (
    <Edit
      title={<Typography variant="h6">Modifier</Typography>}
      isLoading={formLoading}
      saveButtonProps={{ ...saveButtonProps, children: 'Enregistrer' }}
      canDelete
      wrapperProps={{
        sx: {
          marginTop: '3rem',
        },
      }}
    >
      <Box component="form" sx={{ display: 'flex', flexDirection: 'column' }} autoComplete="off">
        <FormLabel component="legend">
          <Typography fontSize="1rem" fontWeight="600" marginBottom="1rem">
            Date à débloquer :
          </Typography>
        </FormLabel>
        <Controller
          control={control}
          name="date"
          render={({ field }) => (
            <DatePicker
              placeholderText="Select date"
              onChange={(date) => field.onChange(dayjs(date).format('YYYY-MM-DD'))}
              selected={typeof field.value === 'string' ? new Date(field.value) : field.value}
              customInput={<TextField />}
              showMonthDropdown
              locale="fr"
              dateFormat={'dd/MM/yyyy'}
            />
          )}
        />
      </Box>
    </Edit>
  );
};
