import { useShow } from '@pankod/refine-core';
import { Show, Stack, Typography } from '@pankod/refine-mui';

import { IGiftCards } from 'interfaces';

export const GiftCardsShow: React.FC = () => {
  const { queryResult } = useShow<IGiftCards>({});

  const { data, isLoading } = queryResult;
  const record = data?.data;

  return (
    <Show
      title={<Typography variant="h6">Détails de la carte</Typography>}
      isLoading={isLoading}
      wrapperProps={{
        sx: {
          marginTop: '3rem',
        },
      }}
    >
      <Stack gap={1}>
        <Typography variant="body1" fontWeight="bold">
          Nom du client
        </Typography>
        <Typography variant="body2">
          {record?.gender} {record?.name}
        </Typography>
        <Typography variant="body1" fontWeight="bold">
          Tél :
        </Typography>
        <Typography variant="body2">{record?.phone}</Typography>
        <Typography variant="body1" fontWeight="bold">
          Email :
        </Typography>
        <Typography variant="body2">{record?.email}</Typography>
        <Typography variant="body1" fontWeight="bold">
          Montant
        </Typography>
        <Typography variant="body2">{record?.amount}€</Typography>
        <Typography variant="body1" fontWeight="bold">
          Pour :
        </Typography>
        <Typography variant="body2">{record?.to}</Typography>

        <Typography variant="body1" fontWeight="bold">
          ID de la carte
        </Typography>
        <Typography variant="body2">{record?.giftCardId}</Typography>
        <Typography variant="body1" fontWeight="bold">
          Déjà utilisée ?
        </Typography>
        <Typography variant="body2">{record?.used ? 'Oui' : 'Non'}</Typography>
        <Typography variant="body1" fontWeight="bold">
          Date d'achat
        </Typography>
        <Typography variant="body2">
          {new Date(record?.createdAt!).toLocaleDateString('fr-FR', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
          })}
        </Typography>
      </Stack>
    </Show>
  );
};
