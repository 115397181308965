import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Typography,
} from '@pankod/refine-mui';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import WhatshotIcon from '@mui/icons-material/Whatshot';
import InfoIcon from '@mui/icons-material/Info';
import AddIcon from '@mui/icons-material/Add';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import ApiIcon from '@mui/icons-material/Api';
import { Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { useList } from '@pankod/refine-core';
import { IFeedback } from '../interfaces';
import BookIcon from '@mui/icons-material/Book';
import DangerousIcon from '@mui/icons-material/Dangerous';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

const FeedbackValue = () => {
  const { data, isLoading } = useList<IFeedback>({
    resource: 'satisfaction',
    config: {
      hasPagination: false,
    },
  });
  // @ts-ignore
  const record = data?.data?.feedbackValueAverageRound as number;

  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <Fragment>
      <List
        breadcrumb={false}
        title={
          <Typography
            variant="h6"
            fontWeight={'bold'}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Informations générales
            <InfoIcon
              sx={{
                marginLeft: 1,
              }}
            />
          </Typography>
        }
      >
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <SentimentSatisfiedAltIcon />
          </ListItemAvatar>
          <ListItemText
            primary="Réservations"
            secondary={
              <Fragment>
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  Satisfaction globale des utilisateurs
                </Typography>
                {` — ${record}/5`}
              </Fragment>
            }
          />
        </ListItem>
        <Divider variant="middle" />
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <ApiIcon />
          </ListItemAvatar>
          <Stack flexDirection={'column'}>
            <ListItemText
              primary="État de l'API"
              secondary={
                <Fragment>
                  {
                    <img
                      src={
                        'https://uptime-kuma-okssg48.gutter8507.cc/api/badge/5/status?upLabel=En%20ligne&downLabel=Hors%20ligne'
                      }
                      loading={'lazy'}
                      style={{
                        marginRight: '.5rem',
                      }}
                      alt={'Impossible d\'afficher l\'état de l\'API'}
                    />
                  }
                  {
                    <img
                      src={
                        'https://uptime-kuma-okssg48.gutter8507.cc/api/badge/5/uptime/720?label=30&labelSuffix=%20jours'
                      }
                      loading={'lazy'}
                      alt={'Impossible d\'afficher l\'état de l\'API'}
                    />
                  }
                </Fragment>
              }
            />
          </Stack>
        </ListItem>
      </List>
    </Fragment>
  );
};

export const Dashboard = () => {
  const navigate = useNavigate();
  return (
    <Box marginTop={6}>
      <Typography variant="h4" marginBottom={3}>
        Tableau de bord
      </Typography>
      <List
        breadcrumb={false}
        title={
          <Typography
            variant="h6"
            fontWeight={'bold'}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Opérations rapides{' '}
            <ArrowDownwardIcon
              sx={{
                marginLeft: 1,
              }}
            />
          </Typography>
        }
      >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid
            item
            xs={6}
            sx={{
              marginTop: 1,
            }}
          >
            <Button
              variant={'contained'}
              color={'primary'}
              fullWidth={true}
              onClick={() => {
                navigate('/bookings');
              }}
              sx={{
                textAlign: 'left',
                height: '60px',
              }}
              startIcon={<BookIcon />}
            >
              Les Réservations
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              marginTop: 1,
            }}
          >
            <Button
              variant={'contained'}
              color={'primary'}
              fullWidth={true}
              onClick={() => {
                navigate('/Dates/blockeds');
              }}
              sx={{
                textAlign: 'left',
                height: '60px',
              }}
              startIcon={<DangerousIcon />}
            >
              Jours bloqués
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              marginTop: 1,
            }}
          >
            <Button
              variant={'contained'}
              color={'primary'}
              fullWidth={true}
              onClick={() => {
                navigate('/Dates/unblockeds');
              }}
              sx={{
                textAlign: 'left',
                height: '60px',
              }}
              startIcon={<TaskAltIcon />}
            >
              Jours débloqués
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              marginTop: 1,
            }}
          >
            <Button
              variant={'contained'}
              color={'primary'}
              fullWidth={true}
              onClick={() => {
                navigate('/Dates/holidays');
              }}
              sx={{
                textAlign: 'left',
                height: '60px',
              }}
              startIcon={<DangerousIcon />}
            >
              Périodes bloquées
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              marginTop: 1,
            }}
          >
            <Button
              variant={'contained'}
              color={'primary'}
              fullWidth={true}
              onClick={() => {
                navigate('/specials');
              }}
              sx={{
                textAlign: 'left',
                height: '60px',
              }}
              startIcon={<RestaurantIcon />}
            >
              Suggestions du jour
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              marginTop: 1,
            }}
          >
            <Button
              variant={'contained'}
              color={'secondary'}
              fullWidth={true}
              onClick={() => {
                navigate('/giftcardsreader');
              }}
              sx={{
                textAlign: 'left',
                height: '60px',
              }}
              startIcon={<QrCodeScannerIcon />}
            >
              Scanner un bon
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            sx={{
              marginTop: 1,
            }}
          >
            <Button
              variant={'contained'}
              color={'primary'}
              fullWidth={true}
              onClick={() => {
                navigate('/orders');
              }}
              sx={{
                textAlign: 'left',
                height: '60px',
              }}
              startIcon={<CardGiftcardIcon />}
            >
              Voir les commandes
            </Button>
          </Grid>
        </Grid>
      </List>
      <Divider
        component={'hr'}
        sx={{
          marginTop: '1.5rem',
          marginBottom: '1.5rem',
          // stretch to fill the container
          width: '100%',
        }}
      />
      <FeedbackValue />
      <Divider
        component={'hr'}
        sx={{
          marginTop: '1.5rem',
          marginBottom: '1.5rem',
          // stretch to fill the container
          width: '100%',
        }}
      />
      <List
        breadcrumb={false}
        title={
          <Typography
            variant="h6"
            fontWeight={'bold'}
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Liste des nouveautés (2.1.1){' '}
            <WhatshotIcon
              sx={{
                marginLeft: 1,
              }}
            />
          </Typography>
        }
      >
        <ListItem alignItems="flex-start">
          <ListItemAvatar>
            <AddIcon />
          </ListItemAvatar>
          <ListItemText
            primary="QR Code"
            secondary={
              <Fragment>
                <Typography
                  sx={{ display: 'inline' }}
                  component="span"
                  variant="body2"
                  color="text.primary"
                >
                  Interface
                </Typography>
                {' — Ajout de la possibilité de traduire la carte'}
              </Fragment>
            }
          />
        </ListItem>
        {/*<Divider variant="middle" />*/}
        {/*<ListItem alignItems="flex-start">*/}
        {/*  <ListItemAvatar>*/}
        {/*    <EngineeringIcon />*/}
        {/*  </ListItemAvatar>*/}
        {/*  <Stack flexDirection={'column'}>*/}
        {/*    <ListItemText*/}
        {/*      primary="Interface"*/}
        {/*      secondary={*/}
        {/*        <Fragment>*/}
        {/*          <Typography*/}
        {/*            sx={{ display: 'inline' }}*/}
        {/*            component="span"*/}
        {/*            variant="body2"*/}
        {/*            color="text.primary"*/}
        {/*          >*/}
        {/*            Amélioration*/}
        {/*          </Typography>*/}
        {/*          {*/}
        {/*            " — Légères modifications de l'interface pour une meilleure expérience utilisateur."*/}
        {/*          }*/}
        {/*        </Fragment>*/}
        {/*      }*/}
        {/*    />*/}
        {/*    <ListItemText*/}
        {/*      secondary={*/}
        {/*        <Fragment>*/}
        {/*          <Typography*/}
        {/*            sx={{ display: 'inline' }}*/}
        {/*            component="span"*/}
        {/*            variant="body2"*/}
        {/*            color="text.primary"*/}
        {/*          >*/}
        {/*            Bug*/}
        {/*          </Typography>*/}
        {/*          {*/}
        {/*            " — Lors d'un retour en arrière une vue non prévue s'affichait contenant uniquement le nom, c'est maintenant corrigé."*/}
        {/*          }*/}
        {/*        </Fragment>*/}
        {/*      }*/}
        {/*    />*/}
        {/*  </Stack>*/}
        {/*</ListItem>*/}
      </List>
    </Box>
  );
};
